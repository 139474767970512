import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

const GeoClimateItem = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 412, h: 229 }
    : { w: 945, h: 275 };

  return (
    <div
      style={{
        padding: '30px 20px',
      }}
    >
      <PlaceholderLoader
        width={width}
        height={height}
        uid="cardSkeleton"
        style={{ height: '100%', width: '100%', display: 'block' }}
      >
        <>
          <rect width="184" height="18" rx="8" />
          <rect x="252" width="267" height="18" rx="8" />
          <rect y="28" width="200" height="1" />
          <rect x="252" y="43" width="693" height="1" />
          <rect x="252" y="94" width="693" height="1" />
          <rect x="252" y="146" width="693" height="1" />
          <rect x="252" y="197" width="693" height="1" />
          <rect x="252" y="248" width="693" height="1" />
          <rect x="252" y="43" width="1" height="205" />
          <rect x="944" y="44" width="1" height="205" />
          <rect x="278" y="118" width="5" height="131" />
          <rect x="336" y="114" width="5" height="135" />
          <rect x="394" y="108" width="5" height="141" />
          <rect x="452" y="101" width="5" height="148" />
          <rect x="510" y="95" width="5" height="154" />
          <rect x="567" y="82" width="5" height="167" />
          <circle cx="569.5" cy="73.5" r="5.5" />
          <rect x="625" y="62" width="5" height="187" />
          <circle cx="627.5" cy="54.5" r="5.5" />
          <rect x="683" y="62" width="5" height="187" />
          <circle cx="685.5" cy="54.5" r="5.5" />
          <rect x="741" y="70" width="5" height="179" />
          <circle cx="743.5" cy="61.5" r="5.5" />
          <rect x="798" y="82" width="5" height="167" />
          <circle cx="800.5" cy="73.5" r="5.5" />
          <rect x="856" y="96" width="5" height="153" />
          <circle cx="858.5" cy="86.5" r="5.5" />
          <rect x="914" y="115" width="5" height="134" />
          <circle cx="916.5" cy="105.5" r="5.5" />
          <circle cx="512.5" cy="84.5" r="5.5" />
          <circle cx="454.5" cy="93.5" r="5.5" />
          <circle cx="396.5" cy="98.5" r="5.5" />
          <circle cx="338.5" cy="104.5" r="5.5" />
          <circle cx="280.5" cy="110.5" r="5.5" />
          <ellipse cx="227.5" cy="36.5" rx="8.5" ry="7.5" />
          <ellipse cx="227.5" cy="87.5" rx="8.5" ry="7.5" />
          <ellipse cx="227.5" cy="138.5" rx="8.5" ry="7.5" />
          <ellipse cx="227.5" cy="189.5" rx="8.5" ry="7.5" />
          <ellipse cx="227.5" cy="241.5" rx="8.5" ry="7.5" />
          <ellipse cx="276.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="334.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="392.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="453.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="511.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="568.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="626.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="684.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="745.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="803.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="857.5" cy="267.5" rx="8.5" ry="7.5" />
          <ellipse cx="914.5" cy="267.5" rx="8.5" ry="7.5" />
        </>
      </PlaceholderLoader>
    </div>
  );
};

const GeoClimateSkeleton: React.FC = () => {
  const counts = 4;
  return (
    <>
      {Array.from({ length: counts }, (_, i) => (
        <div key={i}>
          <GeoClimateItem />
        </div>
      ))}
    </>
  );
};

export default GeoClimateSkeleton;
