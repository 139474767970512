import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { SearchMap } from 'components/domain/srl-map';
import {
  GeoMapWrapper,
  MapContainer,
  MapContainerWrapper,
} from './GeoMap.styled';

export default function GeoMap(): React.ReactElement {
  const [t] = useTranslation('geo');
  const mapContainerRef = useRef<HTMLDivElement>(null);

  return (
    <GeoMapWrapper>
      <h2>{t('geo:map.title')}</h2>
      <MapContainerWrapper>
        <MapContainer ref={mapContainerRef}>
          <SearchMap
            mapContainerRef={mapContainerRef}
            preventBodyScroll={false}
            config={{
              hideRadiusSearch: true,
              hideBackButton: true,
            }}
          />
        </MapContainer>
      </MapContainerWrapper>
    </GeoMapWrapper>
  );
}
