import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import {
  mapGlobalSearchStateToSearchControlCriteriaInput,
  useGSSContext,
} from '@hotelplan/libs.gss';
import useSearchParam from 'components/core/search/useSearchParam';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import {
  Bd4recommendationFragment,
  Bd4TrackingFragment,
} from 'graphql/components/BD4RecommendationGroup.generated';
import { useGetGeoStaticComponentsQuery } from 'graphql/geo/static-components/GetGeoStaticComponents.generated';

type TBd4Group = {
  name: string;
  items: Array<Bd4recommendationFragment>;
  tracking: Bd4TrackingFragment;
};

interface IUseGeoStaticComponents {
  bd4Group: TBd4Group | null;
  loading: boolean;
}

export default function useGeoStaticComponents(): IUseGeoStaticComponents {
  const searchParam = useSearchParam();
  const { id } = useObjectId();
  const { asPath } = useRouter();
  const { gss } = useGSSContext<ISearchControlState>();

  const [bd4Group, setBd4Group] = useState<TBd4Group | null>(null);

  const { loading } = useGetGeoStaticComponentsQuery({
    ssr: false,
    variables: {
      id,
      currentUri: asPath,
      encodedCriteria: searchParam || '',
      gss:
        !searchParam && gss
          ? mapGlobalSearchStateToSearchControlCriteriaInput(gss)
          : undefined,
    },
    onCompleted: data => {
      data?.geo.geoStaticComponents?.forEach(component => {
        if (component?.__typename === 'BD4TravelRecommendationGroup') {
          setBd4Group({
            name: component.name,
            items: component.items,
            tracking: component.tracking,
          });
        }
      });
    },
  });

  return useMemo(
    () => ({
      bd4Group,
      loading,
    }),
    [bd4Group, loading]
  );
}
