import { useObjectId } from '@hotelplan/libs.context.object-id';
import { useGetGeoCheapestOfferQuery } from 'graphql/geo/GetGeoCheapestOffer.generated';
import useGeoSearchCriteria from './useGeoSearchCriteria';

export default function useGeoCheapestOffer() {
  const { id } = useObjectId();
  const { geoCriteriaInput, loading } = useGeoSearchCriteria();

  const { data: cheapestOfferData, loading: cheapestOfferDataLoading } =
    useGetGeoCheapestOfferQuery({
      variables: { id, criteria: geoCriteriaInput },
    });

  return {
    loading: cheapestOfferDataLoading || loading,
    offerInfo: cheapestOfferData?.geo.information.offerInfo,
    link: cheapestOfferData?.geo.information.link,
  };
}
