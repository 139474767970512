import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { IBd4Props } from 'components/domain/bd4';
import SearchControlForm from 'components/domain/search-control/components/SearchControlForm/SearchControlForm';
import CurrentSearchContextProvider from 'components/domain/srl/CurrentSearchContext';
import SearchResults from 'components/domain/srl/SearchResults';
import {
  SrlContentWrapper,
  SrlMobileOverlayWrapper,
} from 'components/domain/srl/SRL.common';
import { GeoStructuredData } from 'components/domain/structured-data';
import useGeoStaticComponents from './useGeoStaticComponents';

const VerticalBd4TravelRecommendation = dynamic<IBd4Props>(
  () =>
    import('components/domain/bd4').then(
      m => m.VerticalBd4TravelRecommendation
    ),
  { ssr: false }
);

const HorizontalBd4TravelRecommendation = dynamic<IBd4Props>(
  () =>
    import('components/domain/bd4').then(
      m => m.HorizontalBd4TravelRecommendation
    ),
  { ssr: false }
);

interface IGeoSrlProps {
  objectName?: string;
}

function GeoSrl({ objectName }: IGeoSrlProps) {
  const { mobile } = useDeviceType();
  const [t] = useTranslation('filters');

  const { bd4Group, loading } = useGeoStaticComponents();
  const [mobileOverlayOpened, open, close] = useToggleState(false);

  return (
    <>
      <GeoStructuredData />
      <CurrentSearchContextProvider>
        <SrlContentWrapper>
          {!mobile ? (
            <div>
              <SearchControlForm />
              <VerticalBd4TravelRecommendation
                name={bd4Group?.name}
                items={bd4Group?.items}
                tracking={bd4Group?.tracking}
                loading={loading}
              />
            </div>
          ) : (
            <SrlMobileOverlayWrapper
              name={'geo-srl-mobile-overlay'}
              title={t('filter.overlay.title')}
              show={mobileOverlayOpened}
              fullScreen
              onClose={close}
            >
              <SearchControlForm closeMobileOverlay={close} />
            </SrlMobileOverlayWrapper>
          )}
          <SearchResults objectName={objectName} openMobileOverlay={open} />
          {mobile ? (
            <HorizontalBd4TravelRecommendation
              name={bd4Group?.name}
              items={bd4Group?.items}
              loading={loading}
            />
          ) : null}
        </SrlContentWrapper>
      </CurrentSearchContextProvider>
    </>
  );
}

export default GeoSrl;
