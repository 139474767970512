import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const GeoMapWrapper = styled.div(
  sx2CssThemeFn({
    px: 3,
    py: 4,
    h2: {
      color: 'secondary',
      fontSize: ['18px', '26px'],
      mb: [2, 3],
    },
  })
);

export const MapContainerWrapper = styled.div.attrs({
  'data-id': `mapContainerWrapper`,
})(
  sx2CssThemeFn({
    width: '100%',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: 'textGray',
  })
);

export const MapContainer = styled.div(
  sx2CssThemeFn({
    background: 'bgLightGrey',
    position: 'relative',
    width: '100%',
    height: ['450px', '600px'],
  })
);
