import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { Bd4recommendationGroupFragmentDoc } from 'graphql/components/BD4RecommendationGroup.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetGeoStaticComponentsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
  currentUri?: Types.InputMaybe<Types.Scalars['String']>;
  encodedCriteria?: Types.InputMaybe<Types.Scalars['String']>;
  id: Types.Scalars['String'];
  gss?: Types.InputMaybe<Types.GlobalSearchStateInput>;
}>;

export type GetGeoStaticComponentsQuery = {
  __typename?: 'Query';
  geo: {
    __typename?: 'GeoContext';
    geoStaticComponents?: Array<
      | { __typename?: 'AccordionComponent' }
      | { __typename?: 'AgencyContactComponent' }
      | { __typename?: 'AgencyRecommendation' }
      | {
          __typename?: 'BD4TravelRecommendationGroup';
          name?: string | null;
          items: Array<{
            __typename?: 'BD4TravelRecommendation';
            offerId: string;
            name: string;
            travelType?: string | null;
            duration: number;
            mealType: string;
            hpRating?: number | null;
            taRating?: number | null;
            taReviews?: number | null;
            productClass: Types.ProductClass;
            type: Types.ProductType;
            giata: string;
            transferIncluded?: boolean | null;
            featureSummary: Array<{
              __typename?: 'ProductFeatureGroup';
              id: string;
              name: string;
              features: Array<{
                __typename?: 'ProductFeature';
                id: string;
                name: string;
                enabled?: boolean | null;
              }>;
            }>;
            geoBreadcrumbs: Array<{
              __typename?: 'GeoObject';
              id: string;
              name: string;
              type: Types.GeoType;
            }>;
            link: {
              __typename?: 'InternalLink';
              caption?: string | null;
              uri: string;
              targetPageType: Types.PageType;
            };
            price: { __typename?: 'Price'; amount: string; currency: string };
            totalPrice: {
              __typename?: 'Price';
              amount: string;
              currency: string;
            };
            transferPrice?: {
              __typename?: 'Price';
              amount: string;
              currency: string;
            } | null;
            image: {
              __typename?: 'Image';
              alt: string;
              resized: Array<{
                __typename?: 'ResizedImage';
                url: string;
                id: string;
              }>;
            };
          }>;
          tracking?: {
            __typename?: 'BD4TravelRecommendationTracking';
            status: Types.Bd4TravelRecommendationStatus;
            productCount?: number | null;
            productIds?: Array<string> | null;
            ptoken?: string | null;
            info?: {
              __typename?: 'BD4TravelRecommendationInfo';
              modelId: string;
              placementId: string;
              strategy: string;
            } | null;
          } | null;
        }
      | { __typename?: 'BenefitsComponent' }
      | { __typename?: 'BlogArticleRecommendation' }
      | { __typename?: 'BrandsComponent' }
      | { __typename?: 'ClimateComponent' }
      | { __typename?: 'ContactModuleComponent' }
      | { __typename?: 'EmployeesComponent' }
      | { __typename?: 'FaqComponent' }
      | { __typename?: 'FlightWithoutPriceRecommendation' }
      | { __typename?: 'FlightWithPriceRecommendation' }
      | { __typename?: 'GeoChildrenComponent' }
      | { __typename?: 'GeoGalleryComponent' }
      | { __typename?: 'GeoRecommendation' }
      | { __typename?: 'HeroMediaGallery' }
      | { __typename?: 'ImageTextComponent' }
      | { __typename?: 'LinkListComponent' }
      | { __typename?: 'MarketingRecommendation' }
      | { __typename?: 'MarketingTeaserComponent' }
      | { __typename?: 'MediaGallery' }
      | { __typename?: 'NewsArticlesComponent' }
      | { __typename?: 'NewsletterSignupComponent' }
      | { __typename?: 'OfferButtonComponent' }
      | { __typename?: 'OffersMapPlaceholderComponent' }
      | { __typename?: 'ProductGalleryWithMapComponent' }
      | { __typename?: 'ProductRecommendation' }
      | { __typename?: 'RequestButtonComponent' }
      | { __typename?: 'ResortTopHotelsComponent' }
      | { __typename?: 'SrlButtonPlaceholderComponent' }
      | { __typename?: 'SrlMapPinsComponent' }
      | { __typename?: 'StaticBlogRecommendation' }
      | { __typename?: 'StaticGeoRecommendation' }
      | { __typename?: 'StaticProductRecommendation' }
      | { __typename?: 'StaticThemeRecommendation' }
      | { __typename?: 'TextComponent' }
      | { __typename?: 'ThemeRecommendation' }
      | { __typename?: 'UspBoxesComponent' }
      | null
    > | null;
  };
};

export const GetGeoStaticComponentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGeoStaticComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentUri' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'encodedCriteria' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gss' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GlobalSearchStateInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'encodedCriteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'encodedCriteria' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentUri' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currentUri' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'gss' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'gss' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'geoStaticComponents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'bd4recommendationGroup' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...Bd4recommendationGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetGeoStaticComponentsQuery__
 *
 * To run a query within a React component, call `useGetGeoStaticComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeoStaticComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeoStaticComponentsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      currentUri: // value for 'currentUri'
 *      encodedCriteria: // value for 'encodedCriteria'
 *      id: // value for 'id'
 *      gss: // value for 'gss'
 *   },
 * });
 */
export function useGetGeoStaticComponentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGeoStaticComponentsQuery,
    GetGeoStaticComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetGeoStaticComponentsQuery,
    GetGeoStaticComponentsQueryVariables
  >(GetGeoStaticComponentsDocument, options);
}
export function useGetGeoStaticComponentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGeoStaticComponentsQuery,
    GetGeoStaticComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetGeoStaticComponentsQuery,
    GetGeoStaticComponentsQueryVariables
  >(GetGeoStaticComponentsDocument, options);
}
export type GetGeoStaticComponentsQueryHookResult = ReturnType<
  typeof useGetGeoStaticComponentsQuery
>;
export type GetGeoStaticComponentsLazyQueryHookResult = ReturnType<
  typeof useGetGeoStaticComponentsLazyQuery
>;
export type GetGeoStaticComponentsQueryResult = Apollo.QueryResult<
  GetGeoStaticComponentsQuery,
  GetGeoStaticComponentsQueryVariables
>;
