import { useTranslation } from 'next-i18next';
import React from 'react';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { InternalLink } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import PrimaryButtonLink from 'components/core/buttons/PrimaryButtonLink';

export interface IGeoOfferCtaProps {
  productsTotal: number;
  link: InternalLink;
}

const GeoOfferCta: React.FC<IGeoOfferCtaProps> = ({
  productsTotal,
  link,
}: IGeoOfferCtaProps) => {
  const [t] = useTranslation('geo');
  const { mobile } = useDeviceType();
  const routerProps = getTargetPageData(link);

  return (
    <RouterLink {...routerProps} passHref>
      <PrimaryButtonLink>
        {t(mobile ? 'geo:allOffers' : 'geo:children.offer.cta.desktop', {
          count: productsTotal,
        })}
      </PrimaryButtonLink>
    </RouterLink>
  );
};

export default GeoOfferCta;
