import React from 'react';
import styled from 'styled-components';
import { Image, INormalizedImage } from '@hotelplan/components.common.image';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import GeoOffer, { IGeoOfferProps } from './GeoOffer';
import GeoOfferCta from './GeoOfferCta';

const GeoChildWrap = styled.div(
  sx2CssThemeFn(({ theme: { media } }) => ({
    '.wrap': {
      display: 'flex',
      borderTop: `1px solid`,
      borderColor: `borderColor`,
      py: 3,
    },

    '.info': {
      px: [2, 3],
      flexShrink: [1, 0],
      flexGrow: 0,
      flexBasis: ['auto', '248px'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    h2: {
      color: 'secondary',

      [media.mobile]: {
        fontSize: 1,
        mb: 1,
      },
    },

    '.description': {
      lineHeight: 1.5,

      fontSize: ['12px', 0],
      display: '-webkit-box',
      '-webkit-line-clamp': ['6', '13'],
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      mb: [3, 0],
    },

    '.image': {
      [media.mobile]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      flexShrink: [0, 1],
      flexBasis: ['60%', 'auto'],

      '& > .button': {
        pr: 2,
        a: {
          pl: ['50px', 0],
        },
      },

      '& > img': {
        borderTopLeftRadius: 'default',
        borderBottomLeftRadius: 'default',
        display: 'block',
        width: ['100%', 'auto'],
        mb: [4, 0],
      },
    },
  }))
);

interface IGeoChildProps extends IGeoOfferProps {
  name: string;
  leadText?: string;
  image: INormalizedImage;
  className?: string;
}

const GeoChild: React.FC<IGeoChildProps> = ({
  name,
  leadText,
  offer,
  image,
  link,
  productsTotal,
  className,
}: IGeoChildProps) => {
  const { mobile } = useDeviceType();

  return (
    <GeoChildWrap className={className}>
      <div className="wrap">
        <div className="info">
          <div>
            <h2>{name}</h2>
            {leadText && (
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: leadText }}
              />
            )}
          </div>
          <GeoOffer offer={offer} productsTotal={productsTotal} link={link} />
        </div>
        <div className="image">
          <Image {...image} />
          {mobile && (
            <div className="button">
              <GeoOfferCta productsTotal={productsTotal} link={link} />
            </div>
          )}
        </div>
      </div>
    </GeoChildWrap>
  );
};

export default GeoChild;
