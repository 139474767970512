import { Trans } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import {
  mapGlobalSearchStateToSearchControlCriteriaInput,
  useGSSContext,
} from '@hotelplan/libs.gss';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import { useGetGeoChildrenQuery } from 'graphql/geo/GetGeoChildren.generated';
import { mapGeoTypeToChildrenTitle } from './Geo.mappers';
import GeoChild from './GeoChild';
import GeoChildrenSkeleton from './GeoChildrenSkeleton.skeleton';

// We show all children offers on a single page, so we supply excessive number
// to `resultsPerPage` and `resultsTotal` to bypass backend pagination
const geoChildrenTotalCount = 1000;

const Title = styled.h2(
  sx2CssThemeFn(() => ({
    p: '30px',

    span: {
      color: 'secondary',
    },
    fontSize: [2, '26px'],
  }))
);

const GeoChildren: React.FC = () => {
  const { mobile } = useDeviceType();
  const { id, objectType } = useObjectId();
  const { gss } = useGSSContext<ISearchControlState>();

  const { data, loading } = useGetGeoChildrenQuery({
    variables: {
      id,
      pagination: {
        pageNumber: 0,
        resultsPerPage: geoChildrenTotalCount,
        resultsTotal: geoChildrenTotalCount,
      },
      gss: gss
        ? mapGlobalSearchStateToSearchControlCriteriaInput(gss)
        : undefined,
    },
  });

  if (loading) {
    return <GeoChildrenSkeleton />;
  }

  return (
    <>
      {!mobile && (
        <Title>
          <Trans
            i18nKey={`geo:${mapGeoTypeToChildrenTitle(objectType)}`}
            components={[<span key="title" />]}
            tOptions={{ title: data?.geo.information.name }}
          />
        </Title>
      )}
      {data?.geo.childrenOffers.childrenOffers.map(destination => (
        <GeoChild
          {...destination}
          key={destination.name}
          name={destination.name || ''}
          offer={destination.offerInfo}
          link={destination.link}
        />
      ))}
    </>
  );
};

export default GeoChildren;
