import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import usePatchRouteWithSearchQuery from 'components/core/search/usePatchRouteWithSearchQuery';
import { ITabsItem } from 'components/core/tabs/Tabs.types';
import { mapGeoTypeToTabTitle } from 'components/domain/geo/Geo.mappers';
import { GeoTabsKey } from 'components/domain/geo/Geo.types';
import GeoChildren from 'components/domain/geo/GeoChildren';
import GeoClimate from 'components/domain/geo/GeoClimate';
import GeoFacts from 'components/domain/geo/GeoFacts';
import GeoMap from 'components/domain/geo/GeoMap/components/GeoMap';
import GeoSrl from 'components/domain/geo/GeoSrl';
import { GetGeoContentQuery } from 'graphql/geo/GetGeoContent.generated';

const useGeoTabs = (data: GetGeoContentQuery['geo']) => {
  const { mobile } = useDeviceType();
  const [t] = useTranslation(['geo, search']);
  const { query } = useRouter();
  const { objectType } = useObjectId();

  const { s } = query as {
    s?: string | undefined;
  };

  const availableTabs = useMemo<GeoTabsKey[]>(() => {
    const tabs = [GeoTabsKey.Srl, GeoTabsKey.Children];
    if (data?.information?.facts || data?.information?.attributes?.length > 0) {
      tabs.push(GeoTabsKey.Facts);
    }
    if (!mobile) {
      tabs.push(GeoTabsKey.Climate, GeoTabsKey.Map);
    }
    return tabs;
  }, [data, mobile]);

  const geoSrlLink = usePatchRouteWithSearchQuery(data?.geoSrlLink, ['s']);

  const tabItems = useMemo<ITabsItem<GeoTabsKey>[]>(() => {
    return [
      {
        tab: GeoTabsKey.Srl,
        routerProps: getTargetPageData(geoSrlLink),
        label: t('geo:tab.srl'),
        component: <GeoSrl objectName={data?.information.name} />,
      },
      {
        tab: GeoTabsKey.Children,
        routerProps: getTargetPageData(data?.geoChildrenLink, { s }),
        label: t(`geo:${mapGeoTypeToTabTitle(objectType)}`),
        component: <GeoChildren />,
      },
      {
        tab: GeoTabsKey.Facts,
        routerProps: getTargetPageData(data?.geoFactsLink, { s }),
        label: t('geo:tab.facts'),
        component: <GeoFacts />,
      },
      {
        tab: GeoTabsKey.Climate,
        routerProps: getTargetPageData(data?.geoClimateLink, { s }),
        label: t('geo:tab.climate'),
        component: <GeoClimate />,
      },
      {
        tab: GeoTabsKey.Map,
        routerProps: getTargetPageData(data?.geoMapLink, { s }),
        label: t('geo:tab.map'),
        component: <GeoMap />,
      },
    ]
      .filter(item => !!item.routerProps)
      .filter(item => availableTabs.includes(item.tab));
  }, [s, data, t, objectType, availableTabs]);

  const getComponentByTab = useCallback(
    (tabKey: GeoTabsKey): ReactElement => {
      return tabItems.filter(item => item.tab === tabKey)[0]?.component;
    },
    [tabItems]
  );

  return {
    tabItems,
    getComponentByTab,
  };
};

export default useGeoTabs;
