import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import {
  DestinationAttributes,
  DestinationAttributesMobile,
} from '@hotelplan/components.common.destination-attributes';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import AttributesWrapper from 'components/core/attributes/AttributesWrapper';
import { useGetGeoFactsQuery } from 'graphql/geo/GetGeoFacts.generated';
import GeoFactsSkeleton from './GeoFactsSkeleton.skeleton';

const FactsSection = styled.div(
  sx2CssThemeFn(() => ({
    px: 4,
    py: 5,

    h2: {
      color: 'secondary',
      mb: 3,
    },

    p: {
      fontSize: 0,
      lineHeight: 1.8,
      mb: '1em',
    },

    a: {
      color: 'primary',

      '&:hover': {
        textDecoration: 'underline',
      },
    },

    '.icon': {
      width: '50px',
      height: '50px',
    },
  }))
);

const AttributesSection = styled.div(
  sx2CssThemeFn(() => ({
    px: [3, 4],
    py: 5,

    h2: {
      color: 'secondary',
      mb: 3,
      fontSize: ['18px', '26px'],
      textAlign: 'center',
    },
    '.accordionContent.active': {
      mx: -3,
    },
  }))
);

const GeoFacts: React.FC = () => {
  const [t] = useTranslation();
  const { id } = useObjectId();
  const { mobile } = useDeviceType();
  const { data, loading } = useGetGeoFactsQuery({ variables: { id } });

  if (loading) {
    return (
      <FactsSection>
        <GeoFactsSkeleton />
      </FactsSection>
    );
  }

  const {
    geo: { information },
  } = data;

  return (
    <>
      {information.facts && (
        <FactsSection
          dangerouslySetInnerHTML={{ __html: data.geo.information.facts }}
        />
      )}
      {!!information.attributes?.length && (
        <AttributesSection>
          <h2>{t('geo:facts.resort.attributes')}</h2>
          <AttributesWrapper>
            {mobile ? (
              <DestinationAttributesMobile
                attributes={information.attributes}
              />
            ) : (
              <DestinationAttributes attributes={information.attributes} />
            )}
          </AttributesWrapper>
        </AttributesSection>
      )}
    </>
  );
};

export default GeoFacts;
