import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { PriceFragmentDoc } from 'graphql/fragments/Price.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetGeoCheapestOfferQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  criteria?: Types.InputMaybe<Types.GeoCriteriaInput>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetGeoCheapestOfferQuery = {
  __typename?: 'Query';
  geo: {
    __typename?: 'GeoContext';
    information: {
      __typename?: 'GeoInfoComponent';
      offerInfo?: {
        __typename?: 'GeoOfferInfo';
        travelTypeInfo?: string | null;
        numberOfNightsInfo?: string | null;
        productsTotal: number;
        cheapestPrice?: {
          __typename?: 'Price';
          amount: string;
          currency: string;
        } | null;
      } | null;
      link?: {
        __typename?: 'InternalLink';
        caption?: string | null;
        uri: string;
        targetPageType: Types.PageType;
      } | null;
    };
  };
};

export type GeoOfferInfoFragment = {
  __typename?: 'GeoOfferInfo';
  travelTypeInfo?: string | null;
  numberOfNightsInfo?: string | null;
  productsTotal: number;
  cheapestPrice?: {
    __typename?: 'Price';
    amount: string;
    currency: string;
  } | null;
};

export const GeoOfferInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'geoOfferInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'GeoOfferInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'travelTypeInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfNightsInfo' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cheapestPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'productsTotal' } },
        ],
      },
    },
    ...PriceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const GetGeoCheapestOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGeoCheapestOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'criteria' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GeoCriteriaInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'criteria' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'information' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offerInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'geoOfferInfo' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'internalLinkFragment',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...GeoOfferInfoFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetGeoCheapestOfferQuery__
 *
 * To run a query within a React component, call `useGetGeoCheapestOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeoCheapestOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeoCheapestOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *      criteria: // value for 'criteria'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetGeoCheapestOfferQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetGeoCheapestOfferQuery,
    GetGeoCheapestOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetGeoCheapestOfferQuery,
    GetGeoCheapestOfferQueryVariables
  >(GetGeoCheapestOfferDocument, options);
}
export function useGetGeoCheapestOfferLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGeoCheapestOfferQuery,
    GetGeoCheapestOfferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetGeoCheapestOfferQuery,
    GetGeoCheapestOfferQueryVariables
  >(GetGeoCheapestOfferDocument, options);
}
export type GetGeoCheapestOfferQueryHookResult = ReturnType<
  typeof useGetGeoCheapestOfferQuery
>;
export type GetGeoCheapestOfferLazyQueryHookResult = ReturnType<
  typeof useGetGeoCheapestOfferLazyQuery
>;
export type GetGeoCheapestOfferQueryResult = Apollo.QueryResult<
  GetGeoCheapestOfferQuery,
  GetGeoCheapestOfferQueryVariables
>;
