import React from 'react';
import styled from 'styled-components';
import {
  ClimateCharts,
  useChartsData,
} from '@hotelplan/components.common.climate';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import SunHoursPerYear from 'components/core/climate/SunHoursPerYear';
import {
  CHART_PARAMS,
  CHART_PARAMS_BY_TYPE,
} from 'components/domain/climate/ClimateCharts.params';
import { CHART_COLORS } from 'components/domain/climate/ClimateCharts.styles';
import { useGetGeoClimateQuery } from 'graphql/geo/GetGeoClimate.generated';
import GeoClimateSkeleton from './GeoClimateSkeleton.skeleton';

const Wrapper = styled.div(
  sx2CssThemeFn({
    px: 3,
    py: 4,

    h2: {
      color: 'secondary',
      fontSize: ['18px', '26px'],
    },

    '.label': {
      fontSize: '10px',
      color: 'textGray',
    },
  })
);

const GeoClimate: React.FC = () => {
  const { id } = useObjectId();
  const { data, loading } = useGetGeoClimateQuery({ variables: { id } });
  const preparedCharts = useChartsData(
    data?.geo.climateData?.charts,
    CHART_PARAMS,
    CHART_PARAMS_BY_TYPE,
    CHART_COLORS
  );

  if (loading) {
    return <GeoClimateSkeleton />;
  }

  return (
    <Wrapper>
      <h2>{data?.geo.information.name}</h2>
      <SunHoursPerYear hours={data?.geo.climateData?.sunHoursPerYear} />
      <ClimateCharts charts={preparedCharts} />
    </Wrapper>
  );
};

export default GeoClimate;
