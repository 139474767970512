import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

const GeoChildrenItem = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 412, h: 229 }
    : { w: 978, h: 408 };

  return (
    <div>
      <PlaceholderLoader
        width={width}
        height={height}
        uid="cardSkeleton"
        style={{ height: '100%', width: '100%', display: 'block' }}
      >
        {mobile ? (
          <>
            <path
              d="M186 23C186 19.6863 188.686 17 192 17H412V139H192C188.686 139 186 136.314 186 133V23Z"
              fill="#D9D9D9"
            />
            <rect x="8" y="45" width="161" height="10" />
            <rect x="8" y="63" width="149" height="10" />
            <rect x="8" y="80" width="157" height="10" />
            <rect x="8" y="98" width="161" height="10" />
            <rect x="8" y="116" width="149" height="10" />
            <rect x="8" y="133" width="157" height="10" />
            <rect x="8" y="175" width="157" height="12" rx="3" />
            <rect x="8" y="193" width="86" height="16" rx="5" />
            <rect x="8" y="19" width="86" height="14" rx="5" />
            <rect x="186" y="175" width="219" height="37" />
            <rect y="1" width="1" height="228" />
            <rect x="411" y="1" width="1" height="228" />
            <rect width="412" height="1" />
            <rect y="228" width="412" height="1" />
          </>
        ) : (
          <>
            <path
              d="M251 22C251 19.2386 253.239 17 256 17H978V391H256C253.239 391 251 388.761 251 386V22Z"
              fill="#D9D9D9"
            />
            <rect x="18" y="362" width="217" height="29" />
            <rect x="17" y="18" width="86" height="22" rx="8" />
            <rect
              x="66"
              y="306"
              width="169"
              height="19"
              rx="8"
              fill="#D9D9D9"
            />
            <rect
              x="128"
              y="332"
              width="107"
              height="19"
              rx="8"
              fill="#D9D9D9"
            />
            <rect x="18" y="48" width="191" height="14" />
            <rect x="17" y="69" width="213" height="14" />
            <rect x="17" y="89" width="197" height="14" />
            <rect x="17" y="110" width="213" height="14" />
            <rect x="17" y="48" width="191" height="14" />
            <rect x="17" y="109" width="213" height="14" />
            <rect x="17" y="149" width="213" height="14" />
            <rect x="17" y="169" width="197" height="14" />
            <rect x="17" y="129" width="191" height="14" />
            <rect x="17" y="190" width="213" height="14" />
            <rect x="17" y="209" width="213" height="14" />
            <rect x="17" y="229" width="197" height="14" />
            <rect x="17" y="250" width="213" height="14" />
            <rect x="1" width="977" height="1" />
            <rect x="1" y="407" width="977" height="1" />
            <rect width="1" height="408" />
            <rect x="977" width="1" height="408" />
          </>
        )}
      </PlaceholderLoader>
    </div>
  );
};

const GeoChildrenSkeleton: React.FC = () => {
  const counts = 5;
  return (
    <>
      {Array.from({ length: counts }, (_, i) => (
        <div key={i}>
          <GeoChildrenItem />
        </div>
      ))}
    </>
  );
};

export default GeoChildrenSkeleton;
