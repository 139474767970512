import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Price } from '@hotelplan/graphql.types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { formatPrice } from '@hotelplan/libs.utils';
import GeoOfferCta, { IGeoOfferCtaProps } from './GeoOfferCta';

const GeoChildOfferWrap = styled.div(
  sx2CssThemeFn(({ theme: { media, text } }) => ({
    [media.mobile]: {
      display: 'flex',
    },
    mt: 'auto',

    '.offer-type': {
      ...text.accent,
      color: 'primary',
      textAlign: ['left', 'right'],
      [media.mobile]: {
        fontSize: 0,
      },
    },

    '.price': {
      ...text.accent,
      color: 'secondary',
      textAlign: ['left', 'right'],
      mb: [1, 1],
      [media.mobile]: {
        fontSize: '14px',
      },

      '.value': {
        fontSize: [2, '26px'],
      },
    },
  }))
);

interface IGeoOffer {
  cheapestPrice?: Omit<Price, 'total'>;
  numberOfNightsInfo?: string;
  travelTypeInfo?: string;
}

export interface IGeoOfferProps extends IGeoOfferCtaProps {
  offer: IGeoOffer;
}

const GeoOffer: React.FC<IGeoOfferProps> = ({
  offer: { cheapestPrice, numberOfNightsInfo, travelTypeInfo },
  productsTotal,
  link,
}: IGeoOfferProps) => {
  const [t] = useTranslation();
  const { mobile } = useDeviceType();

  return (
    <GeoChildOfferWrap>
      <div>
        <p className="offer-type">
          {numberOfNightsInfo} {travelTypeInfo}
        </p>
        <p className="price">
          {t('common:priceFrom')}&nbsp;{cheapestPrice.currency}
          &nbsp;
          <span className="value">
            {formatPrice(cheapestPrice, { currency: false })}
          </span>
        </p>
      </div>
      {!mobile && <GeoOfferCta productsTotal={productsTotal} link={link} />}
    </GeoChildOfferWrap>
  );
};

export default GeoOffer;
