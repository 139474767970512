import { GeoPrefix } from './Geo.types';

const geoTypeToTabTitleMapping = [
  {
    types: [GeoPrefix.Continent, GeoPrefix.CountryGroup],
    title: 'tab.children.countries',
  },
  {
    types: [GeoPrefix.Country, GeoPrefix.Region],
    title: 'tab.children.destinations',
  },
  {
    types: [GeoPrefix.Destination],
    title: 'tab.children.resorts',
  },
];

const geoTypeToChildrenTitleMapping = [
  {
    types: [GeoPrefix.Continent, GeoPrefix.CountryGroup],
    title: 'children.title.countries',
  },
  {
    types: [GeoPrefix.Country, GeoPrefix.Region],
    title: 'children.title.destinations',
  },
  {
    types: [GeoPrefix.Destination],
    title: 'children.title.resorts',
  },
];

interface IGeoTypeToTitleMapping {
  types: GeoPrefix[];
  title: string;
}

const mapGeoTypeToTitle = (
  mapping: IGeoTypeToTitleMapping[],
  geoType: string
) => {
  return mapping.filter(e => e.types.includes(geoType as GeoPrefix))[0]?.title;
};

export const mapGeoTypeToTabTitle = (geoType: string): string => {
  return mapGeoTypeToTitle(geoTypeToTabTitleMapping, geoType);
};

export const mapGeoTypeToChildrenTitle = (geoType: string): string => {
  return mapGeoTypeToTitle(geoTypeToChildrenTitleMapping, geoType);
};
