import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

const FactsSection = styled.div(
  sx2CssThemeFn(() => ({
    display: 'grid',
    gridTemplateColumns: [null, 'repeat(3, minmax(283px, 1fr))'],
    gap: [null, '40px 40px'],
  }))
);

const GeoFactsItem = () => {
  const { mobile } = useDeviceType();

  const { w: width, h: height } = mobile
    ? { w: 329, h: 50 }
    : { w: 283, h: 256 };

  return (
    <div>
      <PlaceholderLoader
        width={width}
        height={height}
        uid="cardSkeleton"
        style={{ height: '100%', width: '100%', display: 'block' }}
      >
        {mobile ? (
          <>
            <rect
              width="327"
              height="1"
              transform="matrix(1 0 0 -1 1 1)"
              fill="#C4C4C4"
            />
            <rect x="33" y="17" width="122" height="16" rx="8" />
            <circle cx="229" cy="25" r="9" />
            <ellipse cx="253" cy="25" rx="10" ry="9" />
            <circle cx="276" cy="25" r="9" />
            <circle cx="298" cy="25" r="9" />
            <circle cx="320" cy="25" r="9" />
            <circle cx="11.5" cy="25.5" r="11.5" />
            <rect
              width="329"
              height="1"
              transform="matrix(1 0 0 -1 0 50)"
              fill="#C4C4C4"
            />
          </>
        ) : (
          <>
            <rect width="283" height="1" transform="matrix(1 0 0 -1 0 1)" />
            <rect y="89" width="114" height="18" rx="9" />
            <rect y="122" width="135" height="18" rx="9" />
            <rect y="154" width="97" height="18" rx="9" />
            <rect x="50" y="21" width="178" height="14" rx="7" />
            <rect y="255" width="283" height="1" />
            <circle cx="59" cy="50" r="9" />
            <circle cx="83" cy="50" r="9" />
            <circle cx="106" cy="50" r="9" />
            <circle cx="129" cy="50" r="9" />
            <circle cx="153" cy="50" r="9" />
            <circle cx="199.5" cy="96.5" r="7.5" />
            <circle cx="218.5" cy="96.5" r="7.5" />
            <circle cx="237.5" cy="96.5" r="7.5" />
            <circle cx="256.5" cy="96.5" r="7.5" />
            <circle cx="275.5" cy="96.5" r="7.5" />
            <circle cx="199.5" cy="130.5" r="7.5" />
            <circle cx="218.5" cy="130.5" r="7.5" />
            <circle cx="237.5" cy="130.5" r="7.5" />
            <circle cx="256.5" cy="130.5" r="7.5" />
            <circle cx="275.5" cy="130.5" r="7.5" />
            <circle cx="199.5" cy="161.5" r="7.5" />
            <circle cx="218.5" cy="161.5" r="7.5" />
            <circle cx="237.5" cy="161.5" r="7.5" />
            <circle cx="256.5" cy="161.5" r="7.5" />
            <circle cx="275.5" cy="161.5" r="7.5" />
            <rect y="184" width="135" height="18" rx="9" />
            <rect y="216" width="97" height="18" rx="9" />
            <circle cx="199.5" cy="192.5" r="7.5" />
            <circle cx="218.5" cy="192.5" r="7.5" />
            <circle cx="237.5" cy="192.5" r="7.5" />
            <circle cx="256.5" cy="192.5" r="7.5" />
            <circle cx="275.5" cy="192.5" r="7.5" />
            <circle cx="199.5" cy="223.5" r="7.5" />
            <circle cx="218.5" cy="223.5" r="7.5" />
            <circle cx="237.5" cy="223.5" r="7.5" />
            <circle cx="256.5" cy="223.5" r="7.5" />
            <circle cx="275.5" cy="223.5" r="7.5" />
            <circle cx="20" cy="41" r="20" />
          </>
        )}
      </PlaceholderLoader>
    </div>
  );
};

const GeoFactsSkeleton: React.FC = () => {
  const counts = 6;
  return (
    <FactsSection>
      {Array.from({ length: counts }, (_, i) => (
        <div key={i}>
          <GeoFactsItem />
        </div>
      ))}
    </FactsSection>
  );
};

export default GeoFactsSkeleton;
